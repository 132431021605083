import { useQueryClient } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { useMemo } from 'react';
import { LocalizedUrl } from 'types/Url';
import { i18nKeys } from '../features';
import { localizedPathnames, pathnames } from '../i18n/pathnames';
import { getLocalizedUrl } from '../utils/localization';
import { prefixWithLocale } from '../utils/urlUtil';

const useLocalizedUrls = () => {
  const queryClient = useQueryClient();
  const { asPath, pathname, query } = useRouter();
  const reduxLocalizedUrls = useSelector((state) => state.localization.localizedUrls);
  const queryLocalizedUrls: LocalizedUrl[] = queryClient.getQueryData(i18nKeys.localizedUrls()) ?? [];

  const localizedUrls = useMemo(
    () => reduxLocalizedUrls || queryLocalizedUrls,
    [reduxLocalizedUrls, queryLocalizedUrls],
  );

  const cmsPage = useSelector((state) => state.cms.pages?.[state.cms.qualifier ?? '']);

  const getLocalizedPathnameByLocale = (locale: string) => {
    const translatedUrl = getLocalizedUrl(localizedUrls, locale);
    if (translatedUrl) return translatedUrl;

    const filteredPathnames = Object.values(pathnames).filter((name) => !name.match(/.*\[.*\].*/));
    const staticPathname = filteredPathnames.find((name) => name === pathname) ?? '';
    const localizedStaticPathname = localizedPathnames[staticPathname]?.[locale];

    if (localizedStaticPathname) return localizedStaticPathname;

    const localizedCMSUrl = cmsPage?.localizedUrls?.entry?.find((url: LocalizedUrl) => url?.key === locale)?.value;

    if (localizedCMSUrl) return localizedCMSUrl;

    if (staticPathname) return staticPathname;

    if (asPath) return asPath;

    return pathnames.INDEX;
  };

  const getLocalizedRouteByLocale = (locale: string) => ({
    asPath: undefined,
    pathname: prefixWithLocale(getLocalizedPathnameByLocale(locale), locale),
    query,
  });

  return { getLocalizedPathnameByLocale, getLocalizedRouteByLocale };
};

export default useLocalizedUrls;
