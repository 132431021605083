import { LocalizedUrl } from 'types/Url';
import { localizedPathnames } from '../i18n/pathnames';

const excludedPaths = ['/homepage-nl', '/homepage-fr'];

export const getLocalizedUrl = (localizedUrls: LocalizedUrl[], locale: string) => {
  if (!localizedUrls || localizedUrls.some((url) => excludedPaths.includes(url.value))) {
    return undefined;
  }

  return localizedUrls?.find((url) => url.key === locale)?.value || undefined;
};

interface GetLocalizedPathnameParams {
  dynamicRouteValue?: string;
  locale: string;
  newValue?: string;
  pathname: string;
}

export const getLocalizedPathname = ({ dynamicRouteValue, locale, newValue, pathname }: GetLocalizedPathnameParams) => {
  const localizedPathname = localizedPathnames[pathname]?.[locale] ?? pathname;
  if (dynamicRouteValue && newValue) {
    return localizedPathname.replace(dynamicRouteValue, newValue);
  }
  return localizedPathname;
};

export const excludeDefaultLocale = (locales: string[]) => locales?.filter((locale) => locale !== 'default');
