import { ALGOLIA_INDEX } from 'constants/algolia';
import { SIGNING_DISPLAY_TYPES } from 'constants/product';
import { useHits } from 'react-instantsearch-core';
import { Hit } from 'types/Algolia';
import { Brand } from 'types/Brand';
import { CompareGroup } from 'types/Compare';
import { Image } from 'types/Image';
import { Atp, EnergyLabel, Product } from 'types/Product';
import { Signing } from 'types/Signing';
import { Store } from 'types/Store';
import { localizedPathnames, pathnames } from '../i18n/pathnames';

export const getSearchUrl = (query: string, locale: string) => {
  const searchParams = new URLSearchParams({ q: query });
  return `/${locale}${localizedPathnames[pathnames.SEARCH][locale]}?${searchParams.toString()}`;
};

export const getAlgoliaIndexName = (locale: string, addQuerySuggestions = false) =>
  `${ALGOLIA_INDEX}${locale}${addQuerySuggestions ? '_query_suggestions' : ''}`;

type Results = ReturnType<typeof useHits>['results'];

export const resultsAreEqual = (previousResults: Results, results: Results) =>
  JSON.stringify(previousResults?.hits.map((product) => product.code)) ===
    JSON.stringify(results?.hits.map((product) => product.code)) &&
  previousResults?.query === results?.query &&
  previousResults?.page === results?.page &&
  previousResults?.hitsPerPage === results?.hitsPerPage;

const mapHitAvailableInStores = (hit: Hit): Store[] => {
  if (!hit?.storeAvailability) {
    return [];
  }
  return Array.isArray(hit.storeAvailability)
    ? hit.storeAvailability?.map((name) => ({ displayName: name }))
    : [{ displayName: hit.storeAvailability }];
};

export const mapHitToProduct = (hit: Hit): Product => {
  const energyLabel: EnergyLabel | undefined = hit?.energyClass
    ? {
        energyCode: hit.energyClass,
        productEnergySheet: {
          url: hit?.energySheet,
        },
      }
    : undefined;

  const compareGroup: CompareGroup =
    hit?.compareGroup && hit?.compareGroupDisplayName
      ? { code: hit.compareGroup, displayName: hit.compareGroupDisplayName }
      : {};

  const signings: Signing[] = [
    {
      colour: hit?.prioritisedSigningColour,
      displayMethodTiles: SIGNING_DISPLAY_TYPES.LABEL,
      image: { url: hit?.prioritisedSigningImage },
      text: hit?.prioritisedSigningText,
    },
  ];

  const atp: Atp = {
    addToCartMessage: hit?.addToCartMessage,
    overruleStockMessage: hit?.overruleStockMessage,
    stockMessage: hit?.stockMessage,
  };

  const brand: Brand = {
    name: hit?.brandName,
  };

  const supplierPromotionLabelArray = Array.isArray(hit?.supplierPromotionLabel)
    ? hit?.supplierPromotionLabel
    : hit?.supplierPromotionLabel
      ? [hit?.supplierPromotionLabel]
      : [];

  const supplierPromotions = supplierPromotionLabelArray.map((label) => ({ code: label, label, type: label }));

  const images: Image[] = hit?.image ? [{ url: hit?.image }] : [];

  const availableInStores: Store[] = mapHitAvailableInStores(hit);

  return {
    atp,
    availableInStores,
    averageRating: hit?.averageRating,
    brand,
    carrier: hit?.carrier,
    code: hit.code,
    compareGroup,
    energyLabel,
    images,
    isAvailableInStores: !!hit?.storeAvailability?.length,
    isPromotional: hit.promotional,
    isSponsored: hit.isSponsored,
    name: hit.name,
    numberOfReviews: hit.numberOfReviews,
    price: {
      value: hit.priceValue,
    },
    signings,
    strikePrice: {
      value: hit.strikePrice,
    },
    supplierPromotions: supplierPromotions,
    topClassifications: {
      features: hit.topClassifications?.features,
    },
    url: hit.url,
    useNewEnergyLabel: hit.useNewEnergyLabel,
  };
};
