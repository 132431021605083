import useRouter from 'hooks/useRouter';
import { useCookies } from 'react-cookie';
import config from '../../../config';
import { DEFAULT_LOCALE } from '../../../constants/i18n';
import useLocalizedUrls from '../../../hooks/useLocalizedUrls';
import { pathnames } from '../../../i18n/pathnames';
import { handleTrailingSlash, isDynamicRoute } from '../../../utils/urlUtil';
import Typography from '../../Typography/Typography';
import { StyledLink } from './LanguageSwitcher.styled';

const { i18n } = config;

interface LanguageSwitcherProps {
  className?: string;
  showDivider?: boolean;
}

const LanguageSwitcher = ({ className, showDivider = true }: LanguageSwitcherProps) => {
  const router = useRouter();
  const [cookies, setCookies] = useCookies();

  const { locale, locales, pathname } = router;

  const { getLocalizedRouteByLocale } = useLocalizedUrls();

  const getTranslatedUrl = (newLocale: string) => {
    const newRoute = getLocalizedRouteByLocale(newLocale);

    if (pathname === pathnames.SEARCH) {
      newRoute.pathname = pathnames.INDEX;
      newRoute.query = {};
    }

    return {
      asPath: undefined,
      pathname: newRoute?.pathname || pathnames.INDEX,
      query: newRoute?.query || undefined,
    };
  };

  if (!locales) {
    return null;
  }

  return (
    <>
      {locales
        .filter((filterLocale) => filterLocale !== locale && filterLocale !== DEFAULT_LOCALE)
        .map((newLocale) => {
          const url = getTranslatedUrl(newLocale);
          const href = handleTrailingSlash(url?.pathname);
          const isDynamic = isDynamicRoute(pathname);

          const handleClick = async () => {
            if (cookies[i18n.key] !== newLocale) {
              setCookies(i18n.key, newLocale, {
                maxAge: i18n.maxAge,
                path: '/',
              });
            }

            const newUrl = new URL(window.location.origin);
            const query = url.query || {};
            newUrl.pathname = href;

            if (!isDynamic) {
              Object.keys(query).forEach((key) => {
                const value = query[key];
                if (!value) return;
                newUrl.searchParams.append(key, value.toString());
              });
            }

            window.location.href = newUrl.toString();
          };

          return (
            <StyledLink
              key={newLocale}
              className={className}
              href={href}
              lang={newLocale}
              onClick={async (event) => {
                event.preventDefault();
                await handleClick();
              }}
              showDivider={showDivider}
            >
              <Typography color="">{newLocale.toUpperCase()}</Typography>
            </StyledLink>
          );
        })}
    </>
  );
};

export default LanguageSwitcher;
