import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useWebConfig } from 'features/configuration/queries';

import { ALGOLIA_LIMITED_APP_KEY } from 'constants/algolia';
import useRouter from 'hooks/useRouter';
import { searchKeys } from '.';
import { NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import {
  fetchAlgoliaApiKey,
  fetchQuerySuggestions,
  fetchRefinementValues,
  fetchSearchSuggestions,
  fetchSorts,
  fetchUserIp,
  searchProducts,
} from './connector';

const useSortValues = () =>
  useQuery({
    queryFn: () => fetchSorts(),
    queryKey: searchKeys.sortValues(),
    refetchOnWindowFocus: false,
  });

const useRefinementValues = (refinements: string[]) =>
  useQuery({
    enabled: refinements?.length > 0,
    queryFn: () => fetchRefinementValues(refinements),
    queryKey: searchKeys.refinementValues(refinements),
    select: (data) => data?.facets || [],
  });

const useSearchProducts = (initialQuery: string, pageQualifier?: string) => {
  const router = useRouter();
  const { query } = router;
  const { currentPage, pageSize, q, sort } = query;

  const currentPageParam = Number(currentPage) || 0;
  const pageSizeParam = Number(pageSize) || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
  const qParam = q || '';
  const sortParam = sort || '';
  const initialQueryParam = initialQuery || '';
  const pageQualifierParam = pageQualifier || '';

  return useQuery({
    placeholderData: keepPreviousData,
    queryFn: () =>
      searchProducts(currentPageParam, pageSizeParam, qParam, sortParam, initialQueryParam, pageQualifierParam),

    queryKey: searchKeys.search(
      currentPageParam,
      pageSizeParam,
      qParam,
      sortParam,
      initialQueryParam,
      pageQualifierParam,
    ),
    refetchOnWindowFocus: false,
  });
};

const useSearchSuggestions = () => {
  const { locale } = useRouter();

  const { data: webConfig } = useWebConfig();

  return useQuery({
    enabled: !!webConfig?.enableSuggestions,
    queryFn: () => fetchSearchSuggestions(),
    queryKey: searchKeys.searchSuggestions(locale),
  });
};

const useQuerySuggestions = (amount = 5) => {
  const { locale } = useRouter();

  return useQuery({
    queryFn: () => fetchQuerySuggestions(amount),
    queryKey: searchKeys.querySuggestions(locale, amount),
    select: (data) => data?.suggestions || [],
  });
};

const useUserIpAddress = () =>
  useQuery({
    queryFn: () => fetchUserIp(),
    queryKey: searchKeys.userIpAddress(),
  });

const useAlgoliaApiKey = () =>
  useQuery({
    queryFn: () => fetchAlgoliaApiKey(),
    queryKey: searchKeys.algoliaApiKey(),
  });

const useUserIsWhitelisted = () => {
  const { data: algoliaApiKey } = useAlgoliaApiKey();
  if (!algoliaApiKey) return false;
  return algoliaApiKey !== ALGOLIA_LIMITED_APP_KEY;
};

export {
  useAlgoliaApiKey,
  useQuerySuggestions,
  useRefinementValues,
  useSearchProducts,
  useSearchSuggestions,
  useSortValues,
  useUserIpAddress,
  useUserIsWhitelisted,
};
