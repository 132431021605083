import { FacetSearchResult } from 'types/Facet';
import { QuerySuggestionsResult, SearchRefinementValues, SearchSuggestionsResult, SortValue } from 'types/Search';
import { logErrorResponse } from 'utils/loggerUtil';
import { apiClient } from '../../helpers/ApiClient';

const fetchSorts = async () => {
  try {
    const { data } = await apiClient<SortValue>({
      url: `/algolia/sorts`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error fetching sorts');
    throw error;
  }
};

const fetchRefinementValues = async (refinements: string[] = []) => {
  try {
    const { data } = await apiClient<SearchRefinementValues>({
      params: {
        codes: refinements,
      },
      url: `/algolia/facets`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error fetching refinements');
    throw error;
  }
};

// TODO: Move to product connector
const searchProducts = async (
  currentPage: number,
  pageSize: number,
  query: string | string[],
  sort: string | string[],
  initialQuery: string,
  pageQualifier: string,
) => {
  const params = {
    currentPage: currentPage || '0',
    fields: 'FULL',
    initialQuery,
    pageQualifier,
    pageSize,
    query,
    sort,
  };

  try {
    const { data } = await apiClient<FacetSearchResult>({
      params,
      url: `/products/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error searching products');
    throw error;
  }
};

const fetchSearchSuggestions = async () => {
  try {
    const { data } = await apiClient<SearchSuggestionsResult>({
      params: { fields: 'FULL' },
      url: `/config/suggestions`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error fetching search suggestions');
    throw error;
  }
};

const fetchQuerySuggestions = async (amount = 5) => {
  try {
    const { data } = await apiClient<QuerySuggestionsResult>({
      params: { amount, fields: 'FULL' },
      url: `/algolia/querysuggestions`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error fetching query suggestions');
    throw error;
  }
};

const fetchUserIp = async () => {
  try {
    const { data } = await apiClient({
      isExternalUrl: true,
      url: `https://api.ipify.org`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error checking is whitelisted ip');
    throw error;
  }
};

const fetchAlgoliaApiKey = async () => {
  try {
    const { data } = await apiClient<{ apiKey: string }>({
      isExternalUrl: true,
      url: `/api/algolia/apiKey`,
    });

    return data?.apiKey;
  } catch (error) {
    logErrorResponse('Search Connector', error, 'Error fetching algolia api key');
    throw error;
  }
};

export {
  fetchAlgoliaApiKey,
  fetchQuerySuggestions,
  fetchRefinementValues,
  fetchSearchSuggestions,
  fetchSorts,
  fetchUserIp,
  searchProducts,
};
