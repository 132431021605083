import { isVercel } from 'constants/environment';
import { ISO_LOCALES } from 'constants/i18n';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { NO_INDEX_PAGES, pathnames } from 'i18n/pathnames';
import Head from 'next/head';
import { useMemo } from 'react';
import config from '../../config';
import { IOS_APP_DATA } from '../../constants/app';
import useLocalizedUrls from '../../hooks/useLocalizedUrls';
import { excludeDefaultLocale } from '../../utils/localization';
import { removeTrailingSlash } from '../../utils/textUtil';
import { prefixWithLocale } from '../../utils/urlUtil';

const getHost = () => {
  if (typeof window !== 'undefined') {
    return `${window?.location?.protocol}//${window?.location?.host}`;
  }
  return `${process.env.NODE_HTTP_ORIGIN}`;
};

const ALLOWED_SEARCH_PARAMS = ['currentPage'];

interface PageHeadProps {
  basePathOverwrite?: string;
  description?: string;
  robots?: string;
  title?: string;
}

const PageHead = ({ basePathOverwrite, description, robots = 'follow, index', title }: PageHeadProps) => {
  const { getLocalizedPathnameByLocale } = useLocalizedUrls();
  const { asPath, locale: currentLocale, locales, pathname } = useRouter();
  const isoLocales: { [key: string]: string | undefined } = ISO_LOCALES[config.platform];

  const host = getHost();
  const alternateUrls = excludeDefaultLocale(locales)?.map((alternateLocale) => ({
    key: alternateLocale,
    value: getLocalizedPathnameByLocale(alternateLocale),
  }));

  const normalizeUrl = (url: string) => removeTrailingSlash(url?.split('?')?.[0]);

  const localizedPathname = prefixWithLocale(
    pathname === pathnames.INDEX ? '' : getLocalizedPathnameByLocale(currentLocale),
    currentLocale,
  );

  const searchParams = new URLSearchParams(asPath?.split('?')?.[1]);
  const whiteListedSeachParams = ALLOWED_SEARCH_PARAMS.reduce((param, key) => {
    const value = searchParams.get(key);
    if (value && Number(value) !== 0) {
      param.set(key, value);
    }
    return param;
  }, new URLSearchParams());
  const canonicalUrl = `${host}${localizedPathname}${
    Array.from(whiteListedSeachParams.keys()).length ? '?' : ''
  }${whiteListedSeachParams.toString()}`;

  const { data: webConfig } = useWebConfig();

  const isEnabledSmartAppBanner = webConfig?.enableAppBanner;

  const showIOSSafariBanner = useMemo(() => {
    if (typeof isEnabledSmartAppBanner !== 'undefined') {
      return !!isEnabledSmartAppBanner;
    }

    return true;
  }, [isEnabledSmartAppBanner]);

  const normalizedAsPath = asPath?.split('?')?.[0]?.split('#')?.[0];
  const isNoIndexPage = NO_INDEX_PAGES.includes(normalizedAsPath);
  const robotsContent = isVercel || isNoIndexPage ? 'noindex, nofollow' : robots;

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="description" content={description} name="description" />
      <link key="canonical" href={canonicalUrl} rel="canonical" />
      {alternateUrls?.map(({ key, value }) => {
        const url = prefixWithLocale(basePathOverwrite || value, key);
        return <link key={key} href={normalizeUrl(`${host}${url}`)} hrefLang={isoLocales[key]} rel="alternate" />;
      })}
      <meta key="og:title" content={title} property="og:title" />
      <meta key="og:description" content={description} property="og:description" />
      <meta key="twitter:title" content={title} name="twitter:title" />
      <meta key="twitter:description" content={description} name="twitter:description" />
      <meta content={robotsContent} name="robots" />

      {/* Smart app banner */}
      {showIOSSafariBanner && <meta content={`app-id=${IOS_APP_DATA.appID}`} name={IOS_APP_DATA.appMeta} />}
    </Head>
  );
};

export default PageHead;
